/* eslint-disable react/jsx-no-comment-textnodes */
import { useCallback, useEffect, useState } from "react";
import { toast, Toaster } from "react-hot-toast";
import { GET_DETAIL, GET_USER_INFO, POST_USER_INFO, VIP_USER_REGISTER } from "services/users";
import { BeatLoader } from "react-spinners";
import { formatBirthdate } from "../utils/helper";
import Dropdown from "component/Dropdown";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setMemberInfo } from "store/member/memberSlice";
import { setVipInfo } from "store/member/vipSlice";
import { setInvalidMemberInfo } from "store/member/invalidMemberSlice";
import useHandleLogout from "hooks/useHandleLogout";
import userInfo from "assets/images/user.png";
import { useLocation } from "react-router-dom";
import moment from "moment";
import "./style.css";

const Layout = (props) => {
  const {
    children,
    isShowPanel = true,
    isShowUserInfo = false,
    onChangePage = () => { },
  } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [language, setlanguage] = useState("ja");
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetchUserInfo, setIsFetchUserInfo] = useState(false);
  const memberInfo = useSelector((state) => state.member.memberInfo);
  const vipInfo = useSelector((state) => state.vip.vipInfo);
  const handleLogout = useHandleLogout();
  const location = useLocation();
  const now = moment();
  let errorToastId = null;

  useEffect(() => {
    const lang = localStorage.getItem("lang");
    setlanguage(lang);
    localStorage.setItem("env", process.env.REACT_APP_EMBED_BUILD_ENV);
  }, [localStorage.getItem("lang")]);

  const setUserInfo = (data) => {
    localStorage.setItem('userInfo', JSON.stringify(data))
  }

  const onRegisterUser = async () => {
    // Hit api user registration for the new user before register name & dob
    VIP_USER_REGISTER()
      .then((res) => {
        const data = res?.data;
        if (data.isVip) {
          dispatch(
            setVipInfo({
              isVip: data.isVip,
            })
          );
          couponExpired();
        }
      })
      .catch((error) => {
        // Handle duplicate toast
        if (errorToastId === null) {
          // Show the error toast
          const toastOptions = {
            duration: Infinity,
            onClose: () => {
              // Reset the errorToastId when the toast is closed
              errorToastId = null;
            },
          };
          errorToastId = toast.error(
            error.data || "Some error occurred",
            toastOptions
          );

          // sent error to sentry
          if (typeof error === "object") {
            console.error("User registration", JSON.stringify(error));
          } else {
            console.error("User registration", error);
          }

          // When there is an error during user registration, redux will be reset and user will be logged out
          dispatch(
            setInvalidMemberInfo({
              error: true,
            })
          );
          dispatch(setMemberInfo());
          if (error?.code === 102) {
            setTimeout(() => {
              handleLogout();
            }, 5000);
          }
          onChangePage("/");
          navigate("/");
        }
      });
  };

  const onGetDetail = useCallback(async () => {
    try {
      setIsLoading(true);
      const res = await GET_DETAIL();
      const data = res?.data;
      // Retrieve data of users who are already registered and set it in redux
      getDetailInfo(data);
    } catch (error) {
      // toast.error(error.message || "Some error occurred");
      if (typeof error === "object") {
        console.error("Get user detail info", JSON.stringify(error));
      } else {
        console.error("Get user detail info", error);
      }
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, [dispatch]);

  const getDetailInfo = (data) => {
    const getBirthdate = formatBirthdate(data?.birthdate).split("-");
    dispatch(
      setMemberInfo({
        first_name: data?.first_name,
        middle_name: data?.middle_name,
        last_name: data?.last_name,
        birthdate: data?.birthdate,
        coupon_no: data?.coupon_no,
        email: data?.email,
        expire_date: data?.expire_date,
        // expire_date: null,
        // expire_date: "2024-09-09", // For testing expiry date without change db
        day: getBirthdate[0] || "",
        month: getBirthdate[1] || "",
        year: getBirthdate[2] || "",
        newsletter: memberInfo?.newsletter
      })
    );
    console.log(data?.id)
    // Hit api user registration for the new user before register name & dob
    if ((!data?.id || !data?.first_name || !data?.birthdate) && !vipInfo.isVip) {
      onChangePage("/");
      navigate("/");
      // onRegisterUser();
    } else {
      couponExpired();
    }
  };

  // useEffect(() => {
  //   onGetDetail();
  // }, [onGetDetail]);

  useEffect(() => {
    // get & post userInfo client
    const getUserInfoFetch = async () => {
      try {
        const res = await GET_USER_INFO();
        const getUserInfo = res;
        if (getUserInfo) {
          setUserInfo(getUserInfo);
          dispatch(
            setVipInfo({
              isVip: res?.is_withdraw == 1 ? false : res.is_vip
            })
          );
        }
        setIsFetchUserInfo(true)
        onGetDetail();
      } catch (error) {
        if(error.status == 404){
          const res = await POST_USER_INFO();
          if(res?.success == true){
            setUserInfo(res?.data);
            dispatch(
              setVipInfo({
                isVip: false,
              })
            );
            onGetDetail();
          }
        }else{
          console.log(error)
          setTimeout(() => {
            handleLogout();
          }, 1000);
        }
      }

    }

    if (!isFetchUserInfo) getUserInfoFetch();

  }, [isFetchUserInfo]);

  const couponExpired = () => {
    const userInfo = location.pathname === "/user-info";
    // When opening the user-info page/URL, we do not need to automatically navigate to the user's access page. 
    if (userInfo) {
      onChangePage("/user-info");
    } else if (
      (now.isAfter(moment(memberInfo?.expire_date)) ||
        memberInfo?.expire_date === null ||
        !memberInfo?.expire_date) &&
      !vipInfo.isVip
    ) {
      // When coupon is expired
      navigate("/stamp");
    } else {
      navigate("/coupon-detail");
    }
    onLoading();
  };

  const onChangeLang = () => {
    if (window?.location?.pathname?.includes("stamp")) {
      window.location.reload();
    }
    onLoading();
  };

  const onLoading = () => {
    setIsLoading(true);
    setLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      setLoading(false);
    }, 1000);
  };

  const UserInfoButton = () => {
    return (
      <div className="absolute left-2 -top-1">
        <div className="cursor-pointer bg-[white] w-[30px] h-7 flex rounded-3xl">
          <img
            alt="arrow-left"
            loading="lazy"
            src={userInfo}
            className="m-auto fill-neutral-700 w-[14px] !cursor-pointer hover:scale-105"
            onClick={() => navigate("/user-info")}
          />
        </div>
      </div>
    );
  };

  return (
    <div
      className={`${loading
        ? "absolute inset-0 cursor-not-allowed pointer-events-none"
        : "font-primary"
        }`}
    >
      {(isLoading || loading) && (
        <span className="!flex loader">
          <BeatLoader size={20} color={"#404040"} />
        </span>
      )}
      <div
        className={`${loading || isLoading
          ? "opacity-20 cursor-not-allowed pointer-events-none"
          : ""
          } w-full lg:max-w-mobile m-auto bg-white overflow-hidden gap-30 font-primary min-h-screen`}
      >
        <div
          className={`flex flex-col items-center ${isShowPanel ? "pt-4 bg-[#fafafa]" : "pt-0 bg-white"
            } pb-24 font-semibold w-full lg:max-w-mobile min-h-screen relative`}
        >
          {isShowPanel ?
            <div className="flex relative w-full justify-center">
              {isShowUserInfo && <UserInfoButton />}
              <div className="hiragino text-sm font-semibold uppercase">
                Guest Coupon
              </div>
              <div className="absolute right-2 -top-1">
                <Dropdown handleChange={onChangeLang} />
              </div>
            </div> :
            <div className="absolute right-1 z-50 p-2 top-1">
              <Dropdown handleChange={onChangeLang} />
            </div>
          }
          {children}
        </div>
      </div>
      <Toaster
        toastOptions={{
          className: "toaster",
        }}
        position="top-center"
        reverseOrder={false}
      />
    </div>
  );
};

export default Layout;
